import Persona from "persona";

export const triggerSelfieVerification = ({
                                            templateId = null,
                                            referenceId = null,
                                            environmentId = null,
                                            inquiryId = null,
                                            sessionToken = null,
                                            onComplete = null,
                                            onReady = null,
                                            onCancel = null,
                                            onError = null
                                          }) => {
  // Client can be started either with a template ID, or with an inquiry ID.
  // We generate the inquiry ID server side and start it via ID, but allow support for template ID style
  const clientOptions = templateId
    ? {
      templateId: `${templateId}`,
      referenceId: `${referenceId}`,
      environmentId: `${environmentId}`
    }
    : {
      inquiryId: `${inquiryId}`,
      sessionToken: `${sessionToken}`
    };
  const client = new Persona.Client({
    ...clientOptions,
    onReady: () => {
      onReady && onReady();
      client.open();
    },
    onComplete: ({ inquiryId, status, fields }) => {
      // Inquiry completed. Optionally tell your server about it.
      console.log(`Sending finished inquiry ${inquiryId} to backend`);
      onComplete && onComplete(inquiryId);
    },
    onCancel: ({ inquiryId, sessionToken }) => {
      onCancel && onCancel({ inquiryId, sessionToken });
    },
    onError: (error) => {
      console.log(error);
      onError && onError(error);
    }
  });
};
